@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");


p.service {
	margin: 0;
	line-height: 1.6;
	font-weight: 100 !important;
	font-size: 1rem !important;
}


/* li.service::before {
	content: '';
	display: block;
	width: 100%;
	height: 1rem;
	position: absolute;
	top: 0;
	left: 0;
	background: linear-gradient(to right, var(--c1) var(--stop), var(--c2) var(--stop));
} */

div.service-desc {
	display: flex;
	align-items: center;
	background-color: #fff !important;
	border-radius: 100px;
	padding: 10px;
	color: rgb(70 70 70);
	overflow: hidden;
	justify-content: center;
	
}

div.service-img{
	align-self: center;
}
.service-index{
	justify-content: center;
	align-self: center;
}
h3.service-index {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 0 0 auto;
	align-self: center;
	margin-right: 1rem;
	width: 3rem;
	height: 3rem;
	/* content: counter(list); */
	padding: 1rem;
	border-radius: 50%;
	color: white;
}

h3#service-index0{
	background-color: #6A64AC;
}
h3#service-index1{
	background-color: #EE478D;
}
h3#service-index2{
	background-color: #60A1A6;
}

h3#service-index3{
	background-color: #DFA829;
}

h3#service-index4{
	background-color: #1F4286;
}

h3#service-index5{
	background-color: #CB6CE6;
}


@media (min-width: 40em) {
	li.service {
		
		padding: 3rem 2rem 2rem;
	}
	
	h5.service {
		/* font-size: 2rem; */
		
	}
	
	h5.service::before {
		
	}
}

.iconbox .box-header .icon-rounded:after {
	content: '';
    position: absolute;
    background-color: var(--primary);
    top: 14px;
    left: -82px;
    height: 54px;
    width: 54px;
    border-radius: 2px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}


.iconbox.style1 {
    padding-left: 82px;
    position: relative;
}
.iconbox .box-header .icon-rounded {
    position: relative;
}
.iconbox .box-header .icon-rounded:before {
    content: "\f127";
    font-family: "Ionicons";
    position: absolute;
    text-align: center;
    color: #ffffff;
    font-size: 28px;
    z-index: 10;
    top: 27px;
    left: -69px;
    font-weight: normal;
    height: 28px;
    width: 30px;
}
.iconbox .box-header .icon-rounded.clock:before {
    /* content: "t"; */
}
.iconbox .box-header .box-title a {
	color:#222222 ;
}
.iconbox .box-header .box-title a:hover {
	color: var(--primary);
}

.iconbox .box-header .box-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
}
a, .imagebox-image:after {
    text-decoration: none;
    color: #333333;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.box-content {
	font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    font-style: inherit;
    vertical-align: baseline;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    background: transparent;
}

.box-header {
	text-transform: capitalize;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 14px;
    color: #222;

}
.MuiCardContent-root.css-46bh2p-MuiCardContent-root
{
	padding-left: 0px !important;
}
.css-r40f8v-MuiTypography-root {
    font-weight: 500 !important;
    font-size: 1.1rem !important;
    line-height: 1.45 !important;
    letter-spacing: 0.09971em !important;
}


.serviceImage:hover:after {
    transform: scale(1);
    -webkit-transform: scale(1);
}
.serviceImage::after{
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--primary);
    width: 100%;
    height: 100%;
    opacity: 0.4;
    transform: scale(0);
    -webkit-transform: scale(0);
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}
.serviceImage {
    position: relative;
}