.col_half { width: 49%; }
.col_third { width: 32%; }
.col_fourth { width: 23.5%; }
.col_fifth { width: 18.4%; }
.col_sixth { width: 15%; }
.col_three_fourth { width: 74.5%;}
.col_twothird{ width: 66%;}
.col_half,
.col_third,
.col_twothird,
.col_fourth,
.col_three_fourth,
.col_fifth{
  position: relative;
  display:inline;
  display: inline-block;
  float: left;
  margin-right: 2%;
  margin-bottom: 20px;
}


/*-=-=-=-=-=-=-=-=-=-=- */
/* Flip Panel */
/*-=-=-=-=-=-=-=-=-=-=- */

.wrapper{ 
display: flex; justify-content: center;}

.panel {
  -webkit-perspective: 600px;
  -moz-perspective: 600px;
}

.panel .front,
.panel .back {
  text-align: center;
}
  
.panel .front {
  height: inherit;
  top: 0;
  width: 100%;
  z-index: 900;
  text-align: center;
 
}

.panel .back {
  height: inherit;
  top: 0;
  z-index: 1000;
  -webkit-transform: rotateY(-180deg);
     -moz-transform: rotateY(-180deg);
  -webkit-transform-style: preserve-3d;
     -moz-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
     -moz-backface-visibility: hidden;
  -webkit-transition: all .4s ease-in-out;
     -moz-transition: all .4s ease-in-out;
    -ms-transition: all .4s ease-in-out;
     -o-transition: all .4s ease-in-out;
      transition: all .4s ease-in-out;
}
.panel.flip .front {
  z-index: 900;
 
}
.panel.flip .back {
  z-index: 1000;
  -webkit-transform: rotateX(0deg) rotateY(0deg);
  -moz-transform: rotateX(0deg) rotateY(0deg);
}
.box1{
  background-color: var(--dark);
  color: var(--secondary);
  width: 100%;
  height: 200px;
  /* margin: 0 auto; */
  padding: 20px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.box2{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  background-color: var(--primary);
  color: var(--light);
  width: 100%;
  height: 200px;
  word-wrap: break-word;
  padding: 20px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}