/********** Template CSS **********/
:root {
  --primary: #ca3538;
  --light: #f1f8ff;
  --dark: #0f172b;
  --secondary: #6d6f72;
  --background: #ca3538;
  font: 16px/22px "Open Sans", "Roboto", Helvetica, Arial, sans-serif !important;
}

.bgi-primary {
  background: var(--primary) !important;
  color: var(--dark) !important;
}

.cl-secondary {
  color: var(--secondary);
}
.btn-hover:hover {
  background-color: var(--light) !important;
  border: 0px !important;
  color: var(--background) !important;
}
h4.txt-dark {
  color: var(--light) !important;
}
.txt-dark.section-title::after {
  position: absolute;
  content: "";
  width: 45px;
  height: 2px;
  top: 50%;
  right: -55px;
  margin-top: -1px;
  background: var(--light);
}
.btn-hover:focus {
  border: 0px !important;
}
.btn-hover {
  border-radius: 0 !important;
}
.ff-secondary {
  font-family: "Pacifico", cursive;
}

.fw-medium {
  font-weight: 600 !important;
}

.fw-semi-bold {
  font-weight: 700 !important;
}
@media only screen and (max-width: 480px) {
  .d-blk {
    display: block !important;
    text-align: center;
  }
  .pti-0 {
    padding: 0px !important;
  }
  .t-center {
    text-align: center;
    line-height: initial;
  }
  
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}

/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity 0.5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

/*** Button ***/
.btn {
  font-family: "Nunito", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  transition: 0.5s;
}

.btn.btn-primary,
.btn.btn-secondary {
  color: #ffffff;
}

.btn.btn-submit {
  color: #000;
  background-color: #ca3538;
  border-color: #ca3538;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 2px;
}

/*** Navbar ***/

.bookdemo-bg {
  background-color: rgba(247, 234, 218, 0.7);
}
.nav-bg {
  background-color: #fff !important;
}
.navbar-dark .navbar-nav .nav-link {
  position: relative;
  margin-left: 25px;
  padding: 35px 0;
  font-size: 18px;
  color: var(--dark) !important;
  /* text-transform: uppercase; */
  font-weight: 500;
  outline: none;
  transition: 0.5s;
}

.navbar-dark .navbar-toggler,
.navbar-light .navbar-toggler {
  color: var(--dark);
}

.sticky-top.navbar-dark .navbar-nav .nav-link {
  padding: 20px 0;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
  color: var(--primary) !important;
}

.navbar-dark .navbar-brand img {
  max-height: 100px;
  transition: 0.5s;
}

.sticky-top.navbar-dark .navbar-brand img {
  height: 100px;
}

@media (max-width: 991.98px) {
  /* .sticky-top.navbar-dark {
       position: relative;
  } */

  .navbar-dark .navbar-collapse {
    margin-top: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  .navbar-dark .navbar-nav .nav-link,
  .sticky-top.navbar-dark .navbar-nav .nav-link {
    padding: 10px 0;
    margin-left: 0;
  }

  .navbar-dark .navbar-brand img {
    max-height: 60px;
  }
  
}

@media (min-width: 992px) {
  .pti-0 {
    margin-top: 100px !important;
  }
  .navbar-dark {
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
  }

  .sticky-top.navbar-dark {
    position: fixed;
  }
  .mn-700 {
    min-height: 695px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .g-5,
  .gy-5 {
    --bs-gutter-y: 1rem;
  }
  .wf-section{
    display: none;
  }
}

.mn-700 h2 {
  font-size: 22px;
}
/*** Hero Header ***/
.hero-section-features {
  max-height: 85vh;
  min-height: 65vh;
  background-color: var(--primary);
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  padding-top: 40px;
  padding-bottom: 70px;
  overflow: visible;
}

.w-background-video {
  height: 500px;
  color: #fff;
  position: relative;
  overflow: hidden;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: 50%;
  background-size: cover;
  margin: auto;
  position: absolute;
  top: -100%;
  bottom: -100%;
  left: -100%;
  right: -100%;
}
video {
  vertical-align: baseline;
  display: inline-block;
}
.container-41 {
  max-width: 1140px;
  flex-direction: row;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  display: flex;
}

.w-container {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
}
.feature-content-copy {
  z-index: 1;
  width: 100%;
  margin-top: 20px;
  position: relative;
}
.herotext-white {
  color: #fff;
  margin-top: 0;
  margin-bottom: 40px;
  font-size: 60px;
  font-weight: 900;
  line-height: 82px;
  display: inline;
}
h1 {
  margin-top: 20px;
  font-size: 38px;
  line-height: 44px;
}
.herotext-yellow {
  color: #ffdd02;
  font-size: 62px;
  line-height: 82px;
  display: inline;
}
.subtext.white {
  max-width: 560px;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 24px;
}

.subtext {
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 18px;
  text-align: justify;
}

.div-block-155 {
  margin-top: 20px;
  position: relative;
}
.btn-yellow {
  height: auto;
  text-align: center;
  object-fit: fill;
  flex-wrap: nowrap;
  margin-top: 0;
  margin-left: 0;
  margin-right: 20px;
  padding: 20px 40px;
  font-family: Ubuntu, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  display: inline-block;
}

.hero-header {
  background: linear-gradient(rgba(15, 23, 43, 0), rgba(15, 23, 43, 0)), url(./img/bg-hero.jpg) no-repeat center center ; 
  background-size: cover;
}
.hero-header-banner {
  background: var(--background) !important;
  
}
@media (max-width: 992px) {
  .hero-section-features {
    margin-top: 0px;
  }

  .herotext-white {
    font-size: 32px;
    font-weight: 900;
    line-height: 22px;
  }
  h1 {
    font-size: 32px;
    line-height: 42px;
  }
  .herotext-yellow {
    font-size: 36px;
    line-height: 42px;
    display: inline;
  }
  .div-block-155 {
    margin-top: 20px;
  }
  .subtext.white {
    font-size: 18px;
  }

  .btn-yellow {
    padding: 10px 20px;
    font-size: 12px;
    font-weight: 500;
  }
}

.hero-header img {
  animation: imgRotate 30s linear infinite;
}

@keyframes imgRotate {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(15deg);
  }
  20% {
    transform: rotate(30deg);
  }
  30% {
    transform: rotate(45deg);
  }
  40% {
    transform: rotate(30deg);
  }
  50% {
    transform: rotate(15deg);
  }
  60% {
    transform: rotate(0deg);
  }
  70% {
    transform: rotate(-20deg);
  }
  80% {
    transform: rotate(-35deg);
  }
  90% {
    transform: rotate(-20deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.breadcrumb-item + .breadcrumb-item::before {
  color: rgba(255, 255, 255, 0.5);
}

/*** Section Title ***/
.section-title {
  position: relative;
  display: inline-block;
}

.section-title::before {
  position: absolute;
  content: "";
  width: 45px;
  height: 2px;
  top: 50%;
  left: -55px;
  margin-top: -1px;
  background: var(--primary);
}

.section-title::after {
  position: absolute;
  content: "";
  width: 45px;
  height: 2px;
  top: 50%;
  right: -55px;
  margin-top: -1px;
  background: var(--primary);
}

.section-title.text-start::before,
.section-title.text-end::after {
  display: none;
}

/*** Service ***/
.service-item {
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.08);
  transition: 0.5s;
}

.service-item:hover {
  background: var(--primary);
}

.service-item * {
  transition: 0.5s;
}

.service-item:hover * {
  color: var(--light) !important;
}

/*** Food Menu ***/
.nav-pills .nav-item .active {
  border-bottom: 2px solid var(--primary);
}

/*** Youtube Video ***/
.video {
  position: relative;
  height: 100%;
  min-height: 500px;
  background: url(./img/App.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.video .btn-play {
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  height: 44px;
  border-radius: 50%;
  border: none;
  outline: none;
  padding: 18px 20px 18px 28px;
}

.video .btn-play:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100px;
  height: 100px;
  background: var(--primary);
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}

.video .btn-play:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100px;
  height: 100px;
  background: var(--primary);
  border-radius: 50%;
  transition: all 200ms;
}

.video .btn-play img {
  position: relative;
  z-index: 3;
  max-width: 100%;
  width: auto;
  height: auto;
}

.video .btn-play span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid var(--dark);
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

#videoModal {
  z-index: 99999;
}

#videoModal .modal-dialog {
  position: relative;
  max-width: 800px;
  margin: 60px auto 0 auto;
}

#videoModal .modal-body {
  position: relative;
  padding: 0px;
}

#videoModal .close {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 0px;
  top: -30px;
  z-index: 999;
  font-size: 30px;
  font-weight: normal;
  color: #ffffff;
  background: #000000;
  opacity: 1;
}

/*** Team ***/
.team-item {
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.08);
  /* height: calc(100% - 30%); */
  height: 100%;
  transition: 0.5s;
}
.team-desc{
  text-align: justify;
  padding: 20px;
  font-size: 15px;
}

.team-item img {
  transition: 0.5s;
}

.team-item:hover img {
  transform: scale(1.1);
}

.team-item:hover {
  height: 100%;
}

.team-item .btn {
  border-radius: 38px 38px 0 0;
}

/*** Testimonial ***/
.testimonial-carousel .owl-item .testimonial-item,
.testimonial-carousel .owl-item.center .testimonial-item * {
  transition: 0.5s;
}

.testimonial-carousel .owl-item.center .testimonial-item {
  background: var(--primary) !important;
  border-color: var(--primary) !important;
}

.testimonial-carousel .owl-item.center .testimonial-item * {
  color: var(--light) !important;
}

.carousel-root {
  width: 64% !important;
  margin: auto !important;
  margin-top: 3% !important;
}

.carousel .slide {
  background: #fff !important;
  color: black;
  height: 100%;
}

.carousel .slide img {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50%;
}

.myCarousel {
  margin-top: -6%;
  width: 54%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  border: 1px solid #ddd;
  height: 286px;
}

.carousel .control-dots {
  padding-left: 5px !important;
  outline: 0;
  bottom: 5% !important;
}

.myCarousel h3 {
  color: #222;
  font-weight: 100;
  letter-spacing: 0.2px;
  margin-bottom: 4px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 17px;
}

.myCarousel h4 {
  text-transform: uppercase;
  margin-top: 0;
  padding-top: 0;
  font-weight: 500;
  color: #787878;
  font-size: 14px;
}

.myCarousel p {
  font-weight: 100 !important;
  line-height: 29px !important;
  color: #222;
  font-size: 15px;
  font-family: sans-serif;
  max-height: 67px;
}

.myCarousel p:before {
  content: "“";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
}

.myCarousel p:after {
  content: "”";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
  line-height: 0;
}

.carousel .control-dots .dot {
  box-shadow: none !important;
  background: var(--primary) !important;
  outline: 0;
}

.carousel.carousel-slider .control-arrow {
  background: var(--primary) !important;
  height: 50px !important;
  position: absolute;
  top: 35% !important;
}

.App {
  text-align: center;
}

@media only screen and (max-width: 934px) {
  .carousel-root {
    outline: 0;
    width: 93% !important;
    margin: auto !important;
  }

  .carousel.carousel-slider .control-arrow {
    display: none !important;
  }
  .myCarousel {
    background: #fafafa;
    margin-top: -9%;
    width: 88%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8%;
    padding-bottom: 12.5%;
    padding-left: 5%;
    padding-right: 5%;
    border: 1px solid #ddd;
    height: 269px;
  }

  .carousel .slide img {
    width: 24% !important;
    border-radius: 50%;
  }
}

/*** Footer ***/
.footer .btn.btn-social {
  margin-right: 5px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light);
  border: 1px solid var(--light);
  border-radius: 35px;
  transition: 0.3s;
}

.footer .btn.btn-social:hover {
  color: var(--dark);
  border: 1px solid var(--dark);
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  color: var(--dark);
  font-size: 15px;
  font-weight: normal;
  text-transform: capitalize;
  transition: 0.3s;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .copyright {
  padding: 25px 0;
  font-size: 15px;
  border-top: 1px solid rgba(256, 256, 256, 0.1);
}

.footer .copyright a {
  color: var(--dark);
}

.footer .footer-menu a {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.footer .footer-menu a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}

.footer-dark {
  background-color: #ca3538 !important;
  color: var(--light) !important;
}
.text-white{
  color: var(--light) !important;
}
.b-100 {
  border-radius: 100px;
}
.skipbackground-left {
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0.9) 20%,
    rgba(255, 255, 255, 1) 20%,
    lightblue 100%
  );
}
.skipbackground-right {
  background: radial-gradient(
      circle at bottom right,
      lightblue 10%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0.9) 100%
    )
    left;
  /* background: linear-gradient(to right, rgba(255, 255, 255, 0.9) 90%, rgba(255, 255, 255, 1) 90%, lightblue 100%); */
}
.skipbackground-grad {
  background: linear-gradient(270deg, #eff2fa, #eff);
}

.titleDemo {
  color: #0a0a0a;
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: Ubuntu, Helvetica, sans-serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 50px;
}
.image-23 {
  margin-top: -150px;
  margin-bottom: -150px;
  margin-left: -185px;
  position: absolute;
}

.ft-18 {
  font-size: 18px;
}
.ft-black {
  color: #fff ;
}

.ftt-black {
  color: #fff !important;
}
.ftt-black:hover {
  color: var(--background) !important;
}
.demotitle-blue {
  color: #ca3538;
}
.MuiPaper-root {
  background-color: transparent !important;
  box-shadow: none !important;
}
.card-sub {
  color: #909090 !important;
  text-align: left !important;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  font-family: Ubuntu, Helvetica, sans-serif !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 32px !important;
  transition: all 0.2s !important;
  text-align: justify !important;
}
.card-m {
  text-align: justify !important;
}

.card-main {
  font-size: 24px !important;
  line-height: 45px !important;
  color: #0a0a0a !important;
  margin-bottom: 0 !important;
  font-weight: 500 !important;
  text-align: center !important;
}
.card-image {
  width: 100% !important;
  height: 400px !important;
  background-color: #fff !important;
  border-radius: 20px !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 50px 30px !important;
  display: flex !important;
}
.spin:after {
  content: "";
  animation: spin 5s linear infinite;
}
.end--10 {
  right: -1in !important;
}
.start--10 {
  left: -0.5in !important;
}

@keyframes spin {
  0% {
    content: "Hi";
    opacity: 0;
    transform: translateY(100%);
  }
  20% {
    content: "Hi";
    opacity: 0.5;
  }
  40% {
    content: "Hi";
    opacity: 1;
  }
  60% {
    content: "How do you like it?";
    opacity: 0;
    transform: translateY(100%);
  }
  80% {
    content: "How do you like it?";
    opacity: 0.5;
  }
  100% {
    content: "How do you like it?";
    opacity: 1;
  }
}

@media (min-width: 450px) and (max-width: 992px) {
  .pti-0 {
    padding: 0px !important;
  }
  .feature-content-copy {
    margin-top: 12%;
    margin-left: 5%;
  }
  .d-cntnts {
    display: contents !important;
  }
  .mn-700 {
    min-height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .mn-700 h1 {
    font-size: 22px;
  }
  .wf-section{
    display: none;
  }
}
@media (max-width: 449px) {
  .feature-content-copy {
    text-align: left;
    margin-left: 5%;
  }
  .feature-content-copy h1 {
    font-size: 26px;
  }
  .feature-content-copy h1 span {
    font-size: 32px;
  }
  .feature-content-copy p {
    text-align: left;
  }
  .hero-section-features {
    display: none;
  }

  .end--10 {
    right: -0.7in !important;
  }
  .start--10 {
    left: -0.3in !important;
  }
}

#become_partner h3 {
  font-size: 18px;
  color: #d82927;
  letter-spacing: 0;
  text-transform: none;
}

.section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 60px;
  padding-top: 100px;
  background: var(--background);
}

.curve {
  position: absolute;
  height: 250px;
  width: 100%;
  bottom: 0;
  text-align: center;
}

.curve::before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 50%;
  width: 50%;
  height: 100%;
  transform: translate(100%, 66%);
  background-color: #fff;
}

.curve::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 50%;
  width: 60%;
  height: 100%;
  background-color: var(--background);
  transform: translate(-4%, 40%);
  /* z-index: -1; */
}
.bubble::after {
  content: "";
  border-top-left-radius: 50% 100%;
  border-top-right-radius: 50% 100%;
  position: absolute;
  bottom: 0;
  z-index: -1;
  width: 100%;
  background-color: #0f0f10;
  height: 85%;
}
.z-9 {
  z-index: 9;
}
.f-center {
  text-align: center;
}
.bg-grey {
  background-color: #f8f8f8 !important;
}
p.availability {
  color: #7f8387;
  font-size: 1.8rem;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

p.timings {
  width: 190px;
  font-size: 1.4rem;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
div.locations {
  min-height: 220px;
  width: 100%;
  font-size: 1.4rem;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.btn:hover {
  color: var(--primary);
  background-color: var(--light);
  border-color: var(--light);
}

.end--10,
.start--10 {
  width: 4rem;
  height: 4rem;
}
.alice-carousel__stage-item {
  margin: 10px !important;
}
.hovertext {
  color: var(--dark);
  font-family: "Heebo",sans-serif;
  font-size: 21px;
  font-weight: 500;
  line-height: 1.5em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 175px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  cursor: pointer;
  -webkit-transition: background-color 0.25s ease-in-out;
  transition: background-color 0.25s ease-in-out;
}
.text-shades {
  color: var(--dark);
  font-family: "Montserrat", Sans-serif;
  font-size: 23px;
  font-weight: 700;
  cursor: pointer;
  -webkit-transition: background-color 0.25s ease-in-out;
  transition: background-color 0.25s ease-in-out;

}
.text-shades:hover {
  color: var(--background);
  transition: all 0.5s;
}
.hovertext:nth-child(2n) {
  background: #d55d60 no-repeat center center;
  opacity: 0.8;
}
.hovertext:nth-child(3n) {
  background: var(--primary) no-repeat center center;
  opacity: 0.8;
}
.hovertext:nth-child(7n) {
  background: #da7274 no-repeat center center;
  opacity: 0.8;
}
.hovertext:nth-child(11n) {
  background: #da7274 no-repeat center center;
  opacity: 0.8;
}
.hovertext:nth-child(13n) {
  background: #da7274 no-repeat center center;
  opacity: 0.8;
}
.hovertext:nth-child(17n) {
  background: #da7274 no-repeat center center;
  opacity: 0.8;
}
.hovertext:nth-child(5n) {
  background: #b63032 no-repeat center center;
  opacity: 0.8;
}
/* .alice-carousel__stage-item {
  filter: grayscale(100%);
}
.alice-carousel__stage-item:hover {
  filter: grayscale(0%);
} */
.hovertext:hover {
  /* background-color: transparent; */
  opacity: 0.9;
  transition: all 0.5s;
}
.breadcrumb-item a:hover {
  color: var(--light);
}
.footer .btn:hover{
  background-color: transparent !important;
  border-color: transparent;
  border-bottom: 1px solid var(--light);
}