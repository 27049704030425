.custom-banners {
    background-color: var(--primary);
  }
  
  .custom-banners .banners {
    font-size: 0;
    padding: 8px 0;
  }
  
  .custom-banners .banners .banner {
    font-family: "Roboto Slab";
    font-size: 10px;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
  }
  
  @media (min-width: 1024px) {
    .custom-banners .banners .banner {
      font-size: 12px;
    }
  }
  
  @media (min-width: 1366px) {
    .custom-banners .banners .banner {
      font-size: 14px;
    }
  }
  
  @media (min-width: 1366px) {
    .custom-banners .banners {
      padding: 14px 0;
    }
  }
  
  .custom-banners.top {
    width: 100%;
  }
  
  @media (min-width: 1024px) {
    .custom-banners.top {
      position: relative;
    }
  }
  
  .custom-banners .banners + .static-banner {
    padding-top: 0;
  }
  
  @media (min-width: 1366px) {
    .custom-banners .static-banner {
      padding: 10px 100px;
    }
  }
  
  @media (min-width: 1024px) {
    .custom-banners .static-banner .static-banner-container {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
  }
  
  .custom-banners .static-banner .static-banner-left {
    padding-bottom: 0;
  }
  
  @media (min-width: 1024px) {
    .custom-banners .static-banner .static-banner-left {
      text-align: left;
      width: calc(100% / 3);
    }
  }
  
  .custom-banners .static-banner .static-banner-left p {
    color: #fff;
    font-family: Lato, sans-serif;
    font-weight: 700;
    line-height: 20px;
  }
  
  .custom-banners .static-banner .static-banner-trust-pilot {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
  }
  
  @media (min-width: 1024px) {
    .custom-banners .static-banner .static-banner-trust-pilot {
      width: calc(100% / 3);
    }
  }
  
  .custom-banners .static-banner .static-banner-trust-pilot .trustpilot-widget {
    max-height: 20px;
    transform: scale(0.7);
  }
  
  @media (min-width: 1024px) {
    .custom-banners .static-banner .static-banner-trust-pilot .trustpilot-widget {
      transform: none;
    }
  }
  
  .custom-banners .static-banner .static-banner-right.show {
    display: none;
  }
  
  @media (min-width: 1024px) {
    .custom-banners .static-banner .static-banner-right.show {
      display: inline-block;
      text-align: right;
      width: calc(100% / 3);
    }
  }
  .GridP {
    grid-column-gap: 20px;
    grid-row-gap: 25px;
    text-align: center;
    grid-template-rows: auto;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-auto-columns: 1fr;
    justify-content: space-around;
    justify-items: stretch;
    margin-top: 20px;
    display: grid;
  }
  .ml-20{
margin-left: 20%;
  }
  
.paddingSection {
    padding: 60px 15px;
}
.innerContainer {
    width: 95%;
    max-width: 1200px;
    
    padding: 0;
    word-wrap: break-word;
}
.home-page-wrapper h2 {
    margin-bottom: 20px;
    font-size: 4.2rem;
    color: #1d2127;
    text-align: center;
}
p.industries {
    color: #7f8387;
    font-size: 1.8rem;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
}
.paddingBottom45 {
    padding-bottom: 45px;
}
img.fa {
  color: white;
  font-size: 45px;
  padding: 10px;
  max-height: 64px;
  left: 0;
  right: 0;
}
.ct-tabs-outer .inner:before {
    content: "";
    display: block;
    padding-top: 50%;
}
.ct-tabs-outer .inner {
    width: 100%;
    height: 175px;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    cursor: pointer;
    -webkit-transition: background-color 0.25s ease-in-out;
    transition: background-color 0.25s ease-in-out;
}
.item.v_associations .inner {
    background: #ae041b  no-repeat center center;
}
.item {
    display: block;
    position: relative;
    margin: 15px 0;
    z-index: 1;
}
.ct-tabs-outer .item h2 {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 400;
    cursor: pointer;
}
.item.v .inner {
    background: #ca3538  no-repeat center center;
}

.v:hover .inner, .btn.btn-darkblue:hover {
    background-color: #fff;
}
/* .ct-u-sectionGrey {
  background-color: rgba(235, 235, 235, 0.4);
} */

.Banner {
  display: flex;
  align-items: flex-end;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin: 30px auto 15px;
  position: relative;
  width: 100%;
  max-width: 1200px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 12px 80px;
  background-color: rgb(246, 247, 247);
}
.primary {
  color: var(--primary);
}

.fw-30 {
  font-size: 30px;
}